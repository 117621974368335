#archive {
  display: flex;
  flex-wrap: wrap;
  max-width: 70vw;
  flex-direction: row;
  justify-content: left;
}

.titlebar {
	display: flex;
	flex-direction: row;
	column-gap: 30px;
}

a {
	text-decoration: none;
}

@media screen and (max-width: 600px) {

	#archive {
		max-width: 90vw;
		top: 10vh;
		position: relative;
		padding-bottom: 10vh;
	}

	.titlebar {
		max-width: 90vw;
		flex-direction: column;
		row-gap: 5px;
		top: 5vh;
		position: relative;
	}

	h2 {
		margin-bottom: 0px;
		margin-top: 0px;
		font-weight: normal;
		font-size: max(3vw, 35px);
	}

}