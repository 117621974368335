/*.card span {
  display: inline-block;
  vertical-align: middle;
}
*/

.card img {
	margin-right: 10px;
	width: 5vw;
}

/*.card:hover img {
	box-shadow: 5px 5px 10px lightpink;
}*/


@media screen and (max-width: 600px) {

	.card img {
		height: 20vw;
		width: auto;
	}

}