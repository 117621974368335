/*
.menu {
	width: 200px;
	position: fixed;
}*/

.clickable {
  cursor: pointer;
}

.title, .header {
  /*visibility: hidden;*/
  position: fixed;
  z-index: 2;
}

h1 {
  position: relative;
  z-index: 3;
}

a {
  color: black;
}

/*a:hover {
  color: lightpink;
  text-shadow: 2px 2px 5px lightcoral;
}
*/
