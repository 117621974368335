.item {
	width: 100%;
	justify-content: center;
}

.item img {
	max-width: 80vw;
	max-height: 80vh;
}


.item span {
	display: none;
}

.item:hover span {
	display: block;
}

.caption {
	position: fixed;
	top: 90vh;
	left: 50%;
	transform: translateX(-50%);
	text-align: center;
	max-width: 80vw;
}


@media screen and (max-width: 600px) {

.caption {
	font-size: 0.8em;
	top: 77vh;
}

.works {
	top: 30px;
}

}