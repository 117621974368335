.works {
  height: 90vh;
  width: 100vw;
  position: fixed;
  display: flex;
  align-items: center;
  top: 20px;
  /*left: 5vw;*/
  flex-direction: column;
  justify-content: center;
}

.works img {
  display: block;
  max-width: 80%;
  max-height: 80%;
  height: auto;
}

.works p {
  display: block;
  max-width: 80%;
}


#rightclick {
  cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" style="font-size: 30px;"><text y="30">&gt;</text></svg>'), auto;
  right: 0px;
}

#leftclick {
  cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" style="font-size: 30px;"><text y="30">&lt;</text></svg>'), auto;
}

.clicker {
  /*visibility: hidden;*/
  width: 30vw;
  height: 100vh;
  position: fixed;
  z-index: 1;
}

.textblock {
  top: 130px;
  position: relative;
  width: min(600px, 70vw);
}

.newsItem {
  margin-bottom: 30px;
}

.newsImg {
  width: max(500px, 50vw);
}

h1 {
  font-size: max(3vw, 45px);
}

a, h1, h3, h4{
  font-weight:normal;
}

@media screen and (max-width: 600px) {

  .works {
    max-width: 90vw;
    max-height: 80vh;
  }

  .works img {
    max-width: 90vw;
    max-height: 55vh;
  }

  h1 {
    /*font-size: 3vw;*/
    font-size: max(3vw, 35px);
  }

  .newsImg {
      width: max(300px, 50vw);
    }

}